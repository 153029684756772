<template>
  <section>
    <b-link
      v-if="collective && collective.isPublished"
      class="text-dark"
      :href="isDraggable || goToSubcommunityWelcome?'':getCollectiveUrl()"
      :to="goToSubcommunityWelcome && !isDraggable && !isPreview ? { name: 'subcommunity', params: { slug: collective.slug, communityId: $route.params.communityId } }:''"
    >
      <b-card class="small-card" :class="isDraggable?'cursor-move grid-item':'cursor-pointer'">
        <div>
          <feather-icon
            v-if="isViewerMember"
            v-b-tooltip.hover.bottom
            icon="UserCheckIcon"
            :title="$t('tooltip.viewer-in-space')"
            size="20"
            class="text-primary float-right zindex-5 user-in-space"
          />
          <feather-icon
            v-if="fromShare && canDelete"
            v-b-tooltip.hover.bottom
            icon="Trash2Icon"
            :title="$t('unshare.tooltip')"
            size="20"
            style="cursor: pointer"
            class="text-primary float-right zindex-5"
            @click.stop.prevent="isUnshareModalVisible = true"
          />
          <card-thumbnail :src="thumbnailSrc" :placeholder="CommunitiesPlaceholder" :alt="title" />
          <h5 class="mt-1 html-text-ellipsis-2">
            {{ title }}
          </h5>
          <p class="m-0 small-card-headline text-secondary" :class="title.length < 19?'html-text-ellipsis-2':'html-text-ellipsis-1'">
            {{ headline }}
          </p>
        </div>
      </b-card>
    </b-link>
    <b-link v-if="!collective.isPublished && isStaff" class="text-dark" :href="getCollectiveUrl()">
      <b-card class="small-card dashed-card">
        <div>
          <card-thumbnail :src="thumbnailSrc" :placeholder="CommunitiesPlaceholder" :alt="title" />
          <h5 class="mt-1 text-secondary html-text-ellipsis-2">
            {{ title }}
          </h5>
          <p class="m-0 small-card-headline text-muted" :class="title.length < 19?'html-text-ellipsis-2':'html-text-ellipsis-1'">
            {{ headline }}
          </p>
        </div>
      </b-card>
    </b-link>
    <unshare-modal
      v-model="isUnshareModalVisible"
      model-type="shared-community"
      @remove-item="handleRemoveItem"
    />
  </section>
</template>

<script>
import CommunitiesPlaceholder from '@/assets/images/placeholders/light/communities.svg';
import { getImageResource } from '@/@core/utils/image-utils';
import CardThumbnail from '@core/components/card-thumbnail/CardThumbnail.vue';
import { getCollectiveUrl } from '@/@core/utils/collective';
import { translateTranslationTable } from '@/@core/libs/i18n/utils';
import { checkPermissions } from '@/@core/utils/roles-utils';
import UnshareModal from '@/@core/components/modal/UnshareModal.vue';
import ToastNotificationsMixin from '@core/mixins/toast-notifications/ToastNotificationsMixin';

export default {
  name: 'CollectiveCard',
  components: { CardThumbnail, UnshareModal },
  mixins: [ToastNotificationsMixin],
  props: {
    collective: {
      type: Object,
      required: true,
    },
    fromShare: {
      type: Boolean,
      default: false,
    },
    isDraggable: {
      type: Boolean,
      default: false,
    },
    isPreview: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      isUnshareModalVisible: false,
    };
  },
  setup() {
    return { CommunitiesPlaceholder };
  },
  computed: {
    title() {
      return translateTranslationTable(this.$store.state.locale.currentLocale, this.collective.name);
    },
    headline() {
      return translateTranslationTable(this.$store.state.locale.currentLocale, this.collective.headline);
    },
    titleShort() {
      if (this.title.length < 19) {
        return this.title;
      }
      return this.trimString(this.title);
    },
    thumbnailSrc() {
      return getImageResource(this.collective.logoURL || this.collective.bgURL || this.collective.header?.banner_url);
    },
    isStaff() {
      return this.$store.getters.currentCollective.isStaff;
    },
    loggedMemberRoles() {
      return this.$store.getters.loggedMemberRoles;
    },
    isViewerMember() {
      return this.collective.viewerStatus === 3;
    },
    canDelete() {
      return (checkPermissions('delete', 'article', this.loggedMemberRoles, this.$store.getters.currentCollective));
    },
    goToSubcommunityWelcome() {
      if (this.collective.mainType === 'Subcommunity') {
        return this.collective.status !== 3;
      }
      return false;
    },
  },
  methods: {
    getCollectiveUrl() {
      return getCollectiveUrl(this.collective);
    },
    trimString(string) {
      let trimmedString;
      if (string === this.title) {
        trimmedString = string.substr(0, 19);
      } else if (string === this.headline) {
        trimmedString = string.substr(0, 40);
      } else {
        trimmedString = trimmedString.substr(0, Math.min(trimmedString.length, trimmedString.lastIndexOf(' ')));
      }

      return `${trimmedString}...`;
    },
    async handleRemoveItem(response) {
      if (response === true) {
        try {
          await this.$store.dispatch('unshareItem', {
            customName: 'sharedCommunities',
            item: {
              itemType: 'communities',
              storedKey: 'sharedCommunities',
              sharedCommunityKey: this.collective.key,
              key: this.collective.key,
            },
          });
          this.notifySuccess(this.$t('unshare.toast-messages.success'));
          this.$emit('load-items');
          this.$emit('close-modal');
        } catch {
          this.notifyError(this.$t('error-message.general-error'));
        }
      }
    },
  },
};
</script>
<style lang="scss" scoped>
@import '~@core/scss/base/bootstrap-include'; // Bootstrap includes

.dashed-card {
  border-style: dashed !important;
  border-width: 3px !important;
  img {
    filter: grayscale(100%);
  }
}
.grid-item {
  box-shadow: 0px 0px 10px -2px $primary !important;
}

.user-in-space {
  background-color: #3ee63e;
  border-radius: 50%;
  position: absolute;
  right: 15px;
  color: white !important;
  padding: 5px;
  font-weight: 700;
  width: 25px;
  height: 25px;
  //border: 1px solid white;
}
</style>
