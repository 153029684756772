<template>
  <div>
    <div v-if="results != false">
      <base-horizontal-container
        #default="{ item }"
        :item-name="translatedType"
        :items="results"
        :total="total"
        :loading="loading"
        :per-page="perPage"
        :placeholder="placeholder"
        :placeholder-message="$t('entities.placeholder-text')"
        :loading-next="isLoadingNextPage"
        scroll-container-class="d-flex align-items-stretch"
        scrollable
      >
        <entities-card :entity="item" />
      </base-horizontal-container>
    </div>

    <base-horizontal-container
      v-else
      #default="{ item }"
      :item-name="translatedType"
      :items="entities"
      :total="total"
      :loading="loading"
      :per-page="perPage"
      :placeholder="placeholder"
      :placeholder-message="$t('entities.placeholder-text')"
      :loading-next="isLoadingNextPage"
      scroll-container-class="d-flex align-items-stretch"
      scrollable
    >
      <entities-card 
        :entity="item" 
        :type="item.type" 
        @removed="handleRemoved($event)" 
      />
    </base-horizontal-container>
    <description-addon v-model="isAppInfoModalVisible" :app-id="62" />
  </div>
</template>

<script>
import WidgetLayoutMixin from '@/@core/mixins/widgets/WidgetLayoutMixin';
import MembersPlaceholder from '@/assets/images/placeholders/light/entities.svg';
import BaseHorizontalContainer from '@core/components/containers/base/BaseHorizontalContainer.vue';
import ListContainerWidget from '@core/widgets/ListContainerWidget.vue';
import { translateTranslationTable } from '@core/libs/i18n/utils';
import {
  ENTITIES_STORE_MODULE_NAME,
  ENTITIES_ACTIONS,
  ENTITIES_GETTERS,
} from '@/views/apps/entities/constants/entities-store-constants';
import entitiesStoreModule from '@/views/apps/entities/store/entitiesStoreModule';
import EntitiesCard from '@/views/apps/entities/components/EntitiesCard.vue';
import DescriptionAddon from '@/@core/widgets/DescriptionAddon.vue';
import WidgetActions from '@/@core/components/widget-actions/WidgetActions.vue';
import AjaxSelect from '@core/components/ajax-select/AjaxSelect.vue';
import OrganizationsAPI from '@/views/apps/organization/store/OrganizationsAPI';
import vSelect from 'vue-select';
import ToastificationContentVue from '@/@core/components/toastification/ToastificationContent.vue';
import FileTypes from '@/@core/constants/FileTypes';
import { quillEditor } from 'vue-quill-editor';
import { checkPermissions } from '@/@core/utils/roles-utils';
import MembersSelect from '@/views/apps/member/components/MembersSelect.vue';

export default {
  name: 'EntitiesListWidget',
  components: {
    BaseHorizontalContainer,
    ListContainerWidget,
    EntitiesCard,
    DescriptionAddon,
    quillEditor,
    vSelect,
    AjaxSelect,
    WidgetActions,
    MembersSelect,
  },
  mixins: [WidgetLayoutMixin],
  props: {
    type: {
      type: Object,
      required: true,
    },
    perPage: {
      type: Number,
      default: 8,
    },
    communityKey: {
      type: String,
      default: undefined,
    },
  },
  data() {
    return {
      currentPage: 0,
      loading: true,
      formInput: {},
      isLoadingNextPage: false,
      formError: null,
      itemImage: null,
      imageSrc: null,
      typesSelect: [],
      isAppInfoModalVisible: false,
      entitiesData: {},
      results: [],
      resultItem: [],
    };
  },
  computed: {
    key() {
      return this.type?.key;
    },
    entities() {
      if (this.$store.getters.entitiesByType[this.key] != null) {
        this.entitiesData = this.$store.getters.entitiesByType[this.key].unpaginated;

        return this.$store.getters.entitiesByType[this.key].unpaginated;
      }
      return [];
    },
    isStaff() {
      return this.collective.isStaff;
    },
    loggedMemberRoles() {
      return this.$store.getters.loggedMemberRoles;
    },
    canAdd() {
      return checkPermissions(
        'create',
        'entity',
        this.loggedMemberRoles,
        this.collective,
      );
    },
    collective() {
      return this.$store.getters.currentCollective;
    },
    loggedMemberRoles() {
      return this.$store.getters.loggedMemberRoles;
    },
    collective() {
      return this.$store.getters.currentCollective;
    },
    FileTypes() {
      return FileTypes;
    },
    communitySlug() {
      return this.$route.params.communityId;
    },
    total() {
      return this.$store.getters[ENTITIES_GETTERS.getTotal]({
        typeKey: this.key,
      });
    },
    apps() {
      return this.$store.getters.apps;
    },
    translatedType() {
      let apps = {};
      if (this.$store.getters.apps?.apps) {
        apps = this.$store.getters.apps.apps;
      } else if (this.$store.getters.apps?.space) {
        apps = this.$store.getters.apps.space.apps;
      }

      const app = Object.values(apps)?.find(
        (item) => item.key === 'entities',
      );
      if (this.typeKey === 'noKey') {
        return (
          translateTranslationTable(
            this.$store.state.locale.currentLocale,
            app?.customizationName || app?.name,
          ) || this.$tc('entities.title', 2)
        );
      }

      if (app?.customization?.[this.typeKey] != null) {
        return (
          translateTranslationTable(
            this.$store.state.locale.currentLocale,
            app?.customization?.[this.typeKey]?.customizationName
              || app?.customization?.[this.typeKey],
          )
          || Object.values(
            app?.customization?.[this.typeKey]?.customizationName
              || app?.customization?.[this.typeKey],
          )
        );
      }
      return translateTranslationTable(
        this.$store.state.locale.currentLocale,
        this.type.namePlural,
      );
    },
    placeholder() {
      return MembersPlaceholder;
    },
    unpaginatedEntitiesTypes(){
      return this.$store.getters.types?.AllEntitiesTypes?.unpaginated;
    }
  },
  beforeCreate() {
    if (!this.$store.hasModule(ENTITIES_STORE_MODULE_NAME)) {
      this.$store.registerModule(
        ENTITIES_STORE_MODULE_NAME,
        entitiesStoreModule,
      );
    }
  },
  async created() {
    this.loading = true;
    await this.fetchEntities();
    await this.types();
    this.loading = false;
  },
  methods: {
    async fetchEntities() {
      const result = await this.$store.dispatch('getItems', {
        itemType: 'entities',
        customName: 'entitiesByType',
        storedKey: this.key,
        forceAPICall: true,
        page: 1,
        requestConfig: {
          orderByDate: -1,
          typeKey: this.key,
        },
        communityKey: this.communityKey,
      });
      console.log('fetch entitites result', result);

      if(result.total === 0){
        this.$emit('type-empty', this.key);
      }
    },
    async types() {
      await this.$store.dispatch('getItems', {
        itemType: 'types',
        storedKey: 'AllEntitiesTypes',
        page: 1,
        perPage: 200,
        requestConfig: {
          modelType: 'entity',
          getCommunityTypes: 1,
          selectAll: 1,
        },
      });

      this.unpaginatedEntitiesTypes?.map((item) => this.typesSelect.push({
        name: item.name[this.locale] || Object.values(item.name)[0],
        key: item.key,
      }));
    },
    async fetchData(page, searchString = '') {
      return await OrganizationsAPI.fetchPage({
        communityKey: this.$store.getters.currentCollective.key,
        listingType: 0,
        searchString: searchString || '',
        typeKey: null,
        page,
        perPage: 16,
      });
    },
    async handleCreateItem() {
      if (!this.formInput.name) {
        return;
      }
      try {
        const requestConfig = { name: {}, description: {}, headline: {} };
        const locale = this.$store.state.locale.currentLocale;
        requestConfig.name[locale] = this.formInput.name;
        requestConfig.typeKey = this.formInput.typeKey;
        requestConfig.link = this.formInput.link;
        requestConfig.description[locale] = this.formInput.description;
        requestConfig.headline[locale] = this.formInput.headline;
        requestConfig.organizationKey = this.formInput.organization?.key;

        const response = await this.$store.dispatch('createItem', {
          item: {
            itemType: 'entities',
            requestConfig,
            storedKey: this.key,
            customName: 'entitiesByType',
          },
          file: this.itemImage,
        });
        this.formInput = {};
        this.itemImage = null;
        this.$toast({
          component: ToastificationContentVue,
          props: {
            title: this.$t('entities.messages.success-create'),
            icon: 'AlertTriangleIcon',
            variant: 'success',
          },
        });
      } catch (error) {
        this.$toast({
          component: ToastificationContentVue,
          props: {
            title: this.$t('entities.messages.error-create'),
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        });
      }
    },
    searchItems(value) {
      this.searchInput = value;
      const searchTerm = value.toLowerCase().trim();
      if (!searchTerm) {
        return (this.results = false);
      }
      this.results = this.entitiesData.filter((item) => Object.values(item.name)[0].toLowerCase().includes(searchTerm));
    },
    async handleRemoved(key) {
      await this.fetchEntities();
    },
  },
};
</script>
