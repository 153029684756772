<template>
  <base-horizontal-container
    :item-name="translatedType"
    :items="people"
    :loading="loading"
    :per-page="perPage"
    :placeholder="placeholder"
    :placeholderMessage="$t('people.placeholder-text')"
    :loading-next="isLoadingNextPage"
    
    scroll-container-class="d-flex align-items-stretch"
    scrollable
    @load-next="handleLoadNextPage"
  >
  
    <template #header>
      <b-form-input
        v-if="searchable"
        ref="seachInput"
        v-model="searchString"
        class="mb-1"
        type="text"
        trim
        autofocus
        :placeholder="$t('people.search-placeholder')"
        @input="handleSearchChange"
      />
    </template>
 
    <template #default="{ item }">
      <speaker-card @fetch-items="fetchData" :speaker="item" :type="type" />
    </template>
  </base-horizontal-container>
</template>

<script>
import { BFormInput } from "bootstrap-vue";

import MembersPlaceholder from "@/assets/images/placeholders/light/people.svg";

import BaseHorizontalContainer from "@core/components/containers/base/BaseHorizontalContainer.vue";
import { translateTranslationTable } from "@/@core/libs/i18n/utils";
import {
  PEOPLE_STORE_MODULE_NAME,
  PEOPLE_ACTIONS,
  PEOPLE_GETTERS,
} from "@/views/apps/people/constants/people-store-constants";
import peopleStoreModule from "@/views/apps/people/store/peopleStoreModule";
import PeopleAPI from "@/views/apps/people/store/PeopleAPI";
import SpeakerCard from "./SpeakerCard.vue";

export default {
  name: "PeopleListWidget",
  components: {
    BFormInput,
    BaseHorizontalContainer,
    SpeakerCard,
  },
  props: {
    type: {
      type: Object,
      required: true,
    },
    perPage: {
      type: Number,
      default: 8,
    },
    eventKey: {
      type: String,
      default: null,
    },
    communityKey: {
      type: String,
      default: null
    },
    update: {
      type: Boolean
    },
    searchable: Boolean,
  },
  data() {
    return {
      currentPage: 0,
      loading: true,
      isLoadingNextPage: false,
      lastLoadedPage: 1,
      fetchedTotal: null,

      searchString: "",
      timeoutId: null,
    };
  },
  computed: {
    people() {
      return this.$store.getters.people[this.type.key]?.unpaginated || [];
    },
    total() {
      return this.fetchedTotal !== null
        ? this.fetchedTotal
        : this.$store.getters[PEOPLE_GETTERS.getTotal]({
            typeKey: this.type.key,
          });
    },
    translatedType() {
      return "translateTranslationTable(this.$store.state.locale.currentLocale, this.type.namePlural)";
    },
    placeholder() {
      return MembersPlaceholder;
    },
    isEvent() {
      if (this.eventKey !== null) {
        return 15;
      }
      return 100;
    },
  },
  watch: {
    update(oldVal, newVal) {
this.fetchData();
    },
    async searchable(searchable) {
      if (!searchable && this.searchString !== "") {
        this.searchString = "";
        this.handleSearchChange();
      }
    },
  },
  async created() {
    this.loading = true;
    await this.fetchData();
    this.loading = false;
  },
  methods: {
    async fetchData() {
      this.update = false;
      await this.$store.dispatch("getItems", {
        itemType: "people",
        page: this.lastLoadedPage,
        storedKey: this.type.key,
        forceAPICall: true,
        communityKey: this.communityKey,
        requestConfig: {
          count: this.isEvent,
          eventKey: this.eventKey != null ? this.eventKey : undefined,
          orderByDate: -1,
          orderBySurname: 1,
          typeKey: this.type.key !== "noKey" ? this.type.key : undefined,
          searchString:
            this.searchString != null && this.searchString.length > 0
              ? this.searchString
              : undefined,
        },
      });
    },
    async handleLoadNextPage() {
      if (this.searchString !== "") {
        return;
      }
      this.lastLoadedPage++;
      await this.fetchData();
      this.isLoadingNextPage = false;
    },
    async handleSearchChange() {
      this.lastLoadedPage = 1;
      await this.fetchData();
    },
  },
};
</script>
