<template>
	<div 
		v-if="item" 
		class="py-2 mx-xl-n3 mx-n2" 
		:class="isMobile ? '' : 'mt-2'" 
		style="background: white; min-height: 40vh"
	>
		<b-row class="px-2" style="max-width: 1300px; margin: auto">
			<!-- MENU -->
			<b-col
				cols="12"
				class="d-flex mb-1 pb-2 px-0 px-lg-2 justify-content-between justify-content-lg-start border-bottom"
				:class="isMobile ? 'justify-content-between' : ''"
			>
				<!-- Menu tabs -->
				<div
					v-for="(tab, index) of menuWithoutDuplicates"
					:key="index"
					class="banner-selector-background d-lg-flex align-items-center mt-50 px-75"
					style="cursor: pointer;"
					:class="{
						'banner-selector-background--active': menuPage === tab.key,
					}"
					@click="clickOnTab(tab.key)"
				>
					<feather-icon
						v-if="!isMobile"
						:icon="tab.icon"
						size="20"
						class="d-block mx-auto mb-md-1"
						:class="menuPage === tab.key ? 'text-primary' : 'menu-text'"
					/>
					<p class="h5 mb-50 mb-lg-1 ml-lg-50" 
						:class="menuPage === tab.key ? 'text-primary' : 'menu-text'"
					>
						{{ tab.title }}
					</p>
				</div>
			</b-col>


			<!-- ################# TABS CONTENT #################-->

			<!-- ABOUT -->
			<b-col v-if="menuPage === 'about'">
				<about-detail 
					:item="item" 
					:item-type="itemType" 
				/>
			</b-col>

			<!-- TICKETS -->
			<b-col 
				v-if="menuPage === 'tickets'" 
				cols="12" class="px-lg-2 mt-lg-2 overflow-hidden"
			>
				<floors-view 
					:room="item.room" 
					:selected-event="item" 
				/>
			</b-col>

			<!-- PLANS -->
			<b-col v-if="menuPage === 'plans'" cols="12" class="px-lg-2 mt-lg-2 overflow-hidden">
				<plans 
					model-type="community" 
					:item-key="item.key" 
					type="events" 
					:space="item" 
				/>
			</b-col>

			<!-- CONTACT FORM AND RESPONSIBLE -->
			<b-col v-if="menuPage === 'contact'" cols="12" class="pt-2 pb-3 d-flex">
				<contact 
					v-if="item" 
					:item="item" 
					:type="itemType" 
					:type-single="itemTypeSingle" 
					@handleEditItem="handleEditItem" 
				/>
			</b-col>

			<!-- MEMBERS -->
			<b-col v-if="menuPage === 'members'">
				<members-table 
					:members="members" 
				/>
			</b-col>

			<!-- AGENDA -->
			<b-col v-if="menuPage === 'agenda'" cols="12" class="px-lg-2 mt-lg-2 overflow-hidden" style="max-height: 60vh">				
				<events-agenda 
					:subcommunity-collective-key="item.key" 
					:is-from-event="true" 
					:community="item" 
					@events-updated="handleEventsUpdated" 
				/>
			</b-col>

			<!-- EVENTS -->
			<b-col v-if="menuPage === 'events'" cols="12" class="px-lg-2 mt-lg-2">
				<events-list 
					:events="events" 
					:community="item" 
				/>
			</b-col>

			<!-- SERVICES -->
			<b-col v-if="menuPage === 'services'" cols="12" class="px-lg-2 mt-lg-2">
				<service-detail 
					:services="services" 
					:community="item" 
				/>
			</b-col>

			<!-- PRODUCTS -->
			<b-col v-if="menuPage === 'products'" cols="12" class="px-lg-2 mt-lg-2">
				<products-detail 
					:products="products" 
					:community="item" 
					:space_target="item" 
				/>
			</b-col>

			<!-- PROJECTS -->
			<b-col v-if="menuPage === 'projects'" cols="12" class="px-lg-2 mt-lg-2">
				<projects-table 
					:items="projects" 
					:community="item" 
				/>
			</b-col>

			<!-- CHALLENGES -->
			<b-col v-if="menuPage === 'challenges'" cols="12" class="px-lg-2 mt-lg-2">
				<challenges-list 
					:challenges="challenges"
					:community="item"  
				/>
			</b-col>

			<!-- COURSES -->
			<b-col v-if="menuPage === 'courses'" cols="12" class="px-lg-2 mt-lg-2">
				<courses-list 
					:courses="courses"
					:community="item"  
					:is-preview="true"
				/>
			</b-col>

			<!-- JOBS -->
			<b-col v-if="menuPage === 'jobs'" cols="12" class="px-lg-2 mt-lg-2">
				<jobs-list 
					:jobs="jobs"
					:community="item"  
					:is-preview="true"
				/>
			</b-col>

			<!-- SECTIONS -->
			<b-col v-if="menuPage === 'sections'" cols="12" class="px-lg-2 mt-lg-2">
				<collectives-list 
					:communities="sections"
					:community="item"  
					:isPreview="true"
				/>
			</b-col>

			<!-- SUBCOMMUNITIES -->
			<b-col v-if="menuPage === 'subcommunities'" cols="12" class="px-lg-2 mt-lg-2">
				<collectives-list
					:communities="subcommunities"
					:community="item"  
					:isPreview="true"
				/>
			</b-col>

			<!-- IDEAS -->
			<b-col v-if="menuPage === 'ideas'" cols="12" class="px-lg-2 mt-lg-2">
				<ideas-detail :item="item" />
			</b-col>

			<!-- MEDIA DOCUMENTS -->
			<b-col v-if="menuPage === 'documents'">
				<media-vertical-list
					v-if="documents && documents.length > 0"
					:items-data="documents"
					media-type="documents"
					:per-page="20"
					:isEditing=false
					:is-list-type-card=true
					:isStaff=false
					:total="documents.length"
					:is-preview="true"
				/>

				<!-- Container without data: Placeholder -->
				<b-row
					v-else
					class="horizontal-placeholder h-100 media-placeholder"
				>
					<b-col cols="12">
						<img :src="placeholderMedia('documents')" />
					</b-col>
					<b-col cols="12">
						<p class="text-primary">
						{{ $t("no-items-available") }}
						</p>
					</b-col>
				</b-row>
			</b-col>

			<!-- MEDIA PRESENTATIONS -->
			<b-col v-if="menuPage === 'presentations'">
				<media-vertical-list
					v-if="presentations && presentations.length > 0"
					:items-data="presentations"
					media-type="presentations"
					:per-page="20"
					:isEditing=false
					:is-list-type-card=true
					:isStaff=false
					:total="presentations.length"
					:is-preview="true"
				/>

				<!-- Container without data: Placeholder -->
				<b-row
					v-else
					class="horizontal-placeholder h-100 media-placeholder"
				>
					<b-col cols="12">
						<img :src="placeholderMedia('presentations')" />
					</b-col>
					<b-col cols="12">
						<p class="text-primary">
						{{ $t("no-items-available") }}
						</p>
					</b-col>
				</b-row>
			</b-col>

			<!-- MEDIA PHOTOS -->
			<b-col v-if="menuPage === 'photos'">
				<media-vertical-list
					v-if="photos && photos.length > 0"
					:items-data="photos"
					media-type="photos"
					:per-page="20"
					:isEditing=false
					:is-list-type-card=true
					:isStaff=false
					:total="photos.length"
					:is-preview="true"
				/>

				<!-- Container without data: Placeholder -->
				<b-row
					v-else
					class="horizontal-placeholder h-100 media-placeholder"
				>
					<b-col cols="12">
						<img :src="placeholderMedia('photos')" />
					</b-col>
					<b-col cols="12">
						<p class="text-primary">
						{{ $t("no-items-available") }}
						</p>
					</b-col>
				</b-row>
			</b-col>

			<!-- MEDIA VIDEOS -->
			<b-col v-if="menuPage === 'videos'">
				<media-vertical-list
					v-if="videos && videos.length > 0"
					:items-data="videos"
					media-type="videos"
					:per-page="20"
					:isEditing=false
					:is-list-type-card=true
					:isStaff=false
					:total="videos.length"
					:is-preview="true"
				/>

				<!-- Container without data: Placeholder -->
				<b-row
					v-else
					class="horizontal-placeholder h-100 media-placeholder"
				>
					<b-col cols="12">
						<img :src="placeholderMedia('videos')" />
					</b-col>
					<b-col cols="12">
						<p class="text-primary">
						{{ $t("no-items-available") }}
						</p>
					</b-col>
				</b-row>
			</b-col>

			<!-- MEDIA AUDIOS -->
			<b-col v-if="menuPage === 'audios'">
				<media-vertical-list
					v-if="audios && audios.length > 0"
					:items-data="audios"
					media-type="audios"
					:per-page="20"
					:isEditing=false
					:is-list-type-card=true
					:isStaff=false
					:total="audios.length"
					:is-preview="true"
				/>

				<!-- Container without data: Placeholder -->
				<b-row
					v-else
					class="horizontal-placeholder h-100 media-placeholder"
				>
					<b-col cols="12">
						<img :src="placeholderMedia('audios')" />
					</b-col>
					<b-col cols="12">
						<p class="text-primary">
						{{ $t("no-items-available") }}
						</p>
					</b-col>
				</b-row>
			</b-col>

			<!-- MEDIA SPREADSHEETS -->
			<b-col v-if="menuPage === 'spreadsheets'">
				<media-vertical-list
					v-if="spreadsheets && spreadsheets.length > 0"
					:items-data="spreadsheets"
					media-type="spreadsheets"
					:per-page="20"
					:isEditing=false
					:is-list-type-card=true
					:isStaff=false
					:total="spreadsheets.length"
					:is-preview="true"
				/>

				<!-- Container without data: Placeholder -->
				<b-row
					v-else
					class="horizontal-placeholder h-100 media-placeholder"
				>
					<b-col cols="12">
						<img :src="placeholderMedia('spreadsheets')" />
					</b-col>
					<b-col cols="12">
						<p class="text-primary">
						{{ $t("no-items-available") }}
						</p>
					</b-col>
				</b-row>
			</b-col>

			<!-- ORGANIZATIONS -->
			<b-col v-if="menuPage === 'organizations'" cols="12" class="px-lg-2 mt-lg-2">
				<organizations-table
					v-if="organizations.length"
					:organizations="organizations"
					:is-for-backoffice="false"
				/>
				<div v-else>
					{{ $t('no-items-available') }}
				</div>
			</b-col>

			<!-- CONTACTS -->
			<b-col v-if="menuPage === 'contacts'" cols="12" class="px-lg-2 mt-lg-2">
				<contacts-table 
					v-if="contacts.length"
					:members="contacts" 
				/>
				<div v-else>
					{{ $t('no-items-available') }}
				</div>
			</b-col>

			<!-- SOCIETIES -->
			<b-col v-if="menuPage === 'societies'" cols="12" class="px-lg-2 mt-lg-2">
				<societies-table 
					v-if="societies.length"
					:organizations="societies" 
					:from-detail=true
				/>
				<div v-else>
					{{ $t('no-items-available') }}
				</div>
			</b-col>

			<!-- PEOPLE -->
			<b-col v-if="menuPage === 'people'" cols="12" class="px-lg-2 mt-lg-2">
				<!-- SPEAKERS -->
				<template v-for="(type) of peopleTypes">
					<b-row v-if="typeHasPeople[type.key] !== false" :key="type.key">
						<b-col>
							<h4 class="text-dark mb-2 font-weight-600">
								{{ getPeopleName(type.key) || translate(type.namePlural) }}
							</h4>
							<people-horizontal-list 
								:type="type" 
								:community-key="item.key" 
								@total-updated="handleTotalPeopleByTypeUpdated(type, $event)" 
							/>
						</b-col>
					</b-row>
				</template>
			</b-col>

			<!-- ENTITIES -->
			<b-col v-if="menuPage === 'entities'" cols="12" class="px-lg-2 mt-lg-2">
				<template>
					<div v-if="entitiesTypes.length">
						<div v-for="(type) in entitiesTypes" :key="type.key" :id="`entities-type-${type.key}`">
							<b-row v-if="entitiesTypes[type.key] !== false" :key="type.key">
								<b-col class="mb-2">
									<h4 class="text-dark mb-2 font-weight-600">
										{{ translate(type.namePlural) }}
									</h4>
									<entities-list-event-detail 
										:type="type" 
										:community-key="item.key" 
										@type-empty="onTypeEmpty(type.key)"
									/>
								</b-col>
							</b-row>
						</div>
					</div>
					<div v-else>
						There are no entities.
					</div>
				</template>
			</b-col>


		</b-row>
	</div>
</template>

<script>
import ToastNotificationsMixin from "@core/mixins/toast-notifications/ToastNotificationsMixin";
import { translateTranslationTable } from "@/@core/libs/i18n/utils";
import AboutDetail from "@/@core/components/about/AboutDetail.vue";
import Contact from "@core/components/contact/Contact.vue";
import Plans from "@core/components/plans/PlansCollective.vue";
import PeopleHorizontalList from "@/views/apps/people/components/PeopleHorizontalList.vue";
import EntitiesListEventDetail from "@/views/apps/entities/components/EntitiesListEventDetail.vue";
import EventsAgenda from "@/views/apps/events/components/EventsAgenda";
import IdeasDetail from "@/views/apps/ideas/components/IdeasDetail.vue";
import ChallengesList from "@/views/apps/challenges/components/ChallengesList.vue";
import SpacesList from '@/views/collectives-list/SpacesList.vue';
import ServiceDetail from "@/views/apps/services/components/ServiceDetail.vue";
import ProductsDetail from "@/views/apps/products/components/ProductDetailPreview.vue";
import MembersTable from "@/views/apps/member/components/MembersTable.vue";
import CoursesList from "@/views/apps/courses/components/CoursesList.vue";
import EventsList from "@/views/apps/events/components/EventsList.vue";
import ContactsTable from "@/views/apps/contacts/components/ContactsTable.vue";
import SocietiesTable from "@/views/apps/societies/components/SocietyTable.vue";
import ProjectsTable from "@/views/apps/projects/components/ProjectTable.vue";
import CollectivesList from "@/views/apps/collectives/components/CollectivesList.vue";
import FloorsView from "@/views/apps/rooms/components/presencial-rooms/floorsView.vue";
import entitiesStoreModule from "@/views/apps/entities/store/entitiesStoreModule";
import { ENTITIES_STORE_MODULE_NAME, ENTITIES_ACTIONS } from "@/views/apps/entities/constants/entities-store-constants";
import { PEOPLE_STORE_MODULE_NAME } from "@/views/apps/people/constants/people-store-constants";
import peopleStoreModule from "@/views/apps/people/store/peopleStoreModule";
import Service from "@/config/service-identifiers";
import { PlaceholdersImage } from "@/views/apps/media/constants/MediaType";

export default {
	name: "CollectiveDetailInfo",
	components: {
		AboutDetail,
		PeopleHorizontalList,
		EntitiesListEventDetail,
		EventsAgenda,
		Contact,
		Plans,
		IdeasDetail,
		ChallengesList,
		ServiceDetail,
		ProductsDetail,
		MembersTable,
		FloorsView,
		CoursesList,
		EventsList,
		ContactsTable,
		SocietiesTable,
		ProjectsTable,
		CollectivesList,
		SpacesList,
    	MediaVerticalList: () => import ("@/views/apps/media/components/MediaVerticalList.vue"  /* webpackChunkName: "MediaVerticalList" */),
		OrganizationsTable: () => import ("@/views/apps/organization/components/OrganizationsTable.vue"  /* webpackChunkName: "OrganizationsTable" */),
		JobsList: () => import ("@/views/apps/jobs/components/JobsList.vue"  /* webpackChunkName: "JobsList" */),
	},
	mixins: [ToastNotificationsMixin],
	props: {
		itemTypeSingle: {
			type: String,
			required: true,
		},
		itemType: {
			type: String,
			required: true,
		},
		item: {
			type: Object,
			required: true,
		},
		isMobile: {
			type: Boolean,
			default: false,
		},
	},
	data() {
		return {
			menuPage: "about",
			typeHasPeople: {},
			// menu
			menu: [],
			menuObject: {
				'about': {
					key: "about",
					title: this.$t("events.event-details.about.title"),
					icon: "AirplayIcon",
					order: 0,
				},
				'members': {
					key: "members",
					title: this.$t("members.title"),
					icon: "UserIcon",
					order: 1,
				},
				'people': {
					key: "people",
					title: this.$t("events.actions.people"),
					icon: "UsersIcon",
					order: 2,
				},
				'entities': {
					key: "entities",
					title: this.$t("events.actions.entities"),
					icon: "AnchorIcon",
					order: 3,
				},
				// 'events': {
				// 	key: "events",
				// 	title: this.$t("events.title"),
				// 	icon: "CalendarIcon",
				// 	order: 4,
				// },
				'agenda': {
					key: "agenda",
					title: this.$t("schedule.title"),
					icon: "CalendarIcon",
					order: 5,
				},
				'services': {
					key: "services",
					title: this.$t("services.title"),
					icon: "ToolIcon",
					order: 6,
				},
				'products': {
					key: "products",
					title: this.$t("products.title"),
					icon: "ShoppingBagIcon",
					order: 7,
				},
				'organizations': {
					key: "organizations",
					title: this.$t("organizations.title"),
					icon: "SunIcon",
					order: 8,
				},
				'projects': {
					key: "projects",
					title: this.$t("projects.title"),
					icon: "SunIcon",
					order: 9,
				},
				'documents': {
					key: "documents",
					title: this.$t("documents.title"),
					icon: "SunIcon",
					order: 10,
				},
				'presentations': {
					key: "presentations",
					title: this.$t("presentations.title"),
					icon: "SunIcon",
					order: 11,
				},
				'photos': {
					key: "photos",
					title: this.$t("photos.title"),
					icon: "SunIcon",
					order: 12,
				},
				'videos': {
					key: "videos",
					title: this.$t("videos.title"),
					icon: "SunIcon",
					order: 13,
				},
				'audios': {
					key: "audios",
					title: this.$t("audios.title"),
					icon: "SunIcon",
					order: 14,
				},
				'spreadsheets': {
					key: "spreadsheets",
					title: this.$t("spreadsheets.title"),
					icon: "SunIcon",
					order: 15,
				},
				'subcommunities': {
					key: "subcommunities",
					title: this.$t("subcommunities.title"),
					icon: "SunIcon",
					order: 16,
				},
				'sections': {
					key: "sections",
					title: this.$t("sections.title"),
					icon: "SunIcon",
					order: 17,
				},
				'courses': {
					key: "courses",
					title: this.$t("courses.title"),
					icon: "SunIcon",
					order: 18,
				},
				'jobs': {
					key: "jobs",
					title: this.$t("jobs.title"),
					icon: "SunIcon",
					order: 19,
				},
				'challenges': {
					key: "challenges",
					title: this.$t("challenges.title"),
					icon: "SunIcon",
					order: 20,
				},
				'ideas': {
					key: "ideas",
					title: this.$t("challenges.ideas"),
					icon: "SunIcon",
					order: 21,
				},
				'societies': {
					key: "societies",
					title: this.$t("societies.title"),
					icon: "SunIcon",
					order: 22,
				},
				'contacts': {
					key: "contacts",
					title: this.$t("contacts.title"),
					icon: "UsersIcon",
					order: 23,
				},
				'tickets': {
					key: "tickets",
					title: this.$t("tickets.title"),
					icon: "PocketIcon",
					order: 24,
				},
				'plans': {
					key: "plans",
					title: this.$t("backoffice.subscriptions.title"),
					icon: "DollarSignIcon",
					order: 25,
				},
				'contact': {
					key: "contact",
					title: this.$t("services.contact.title"),
					icon: "MailIcon",
					order: 26,
				}
			},
			peopleTypes: [],
			entitiesTypes: [],
			events: [],
			members: [],
			services: [],
			products: [],
			challenges: [],
			courses: [],
			jobs: [],
			contacts: [],
			organizations: [],
			societies: [],
			ideas: [],
			sections: [],
			subcommunities: [],
			projects: [],
			documents: [],
			presentations: [],
			spreadsheets: [],
			photos: [],
			videos: [],
			audios: [],
		};
	},
	computed: {
		menuWithoutDuplicates() {
			const menuWithoutDuplicates = this.menu.reduce((acc, current) => {
				if(current === undefined){
					return acc;
				}
				const x = acc.findIndex((item) => item.key === current?.key);
				if (x === -1) {
					return acc.concat([current]);
				}
				acc[x] = current;
				return acc;
			}, []);
			return menuWithoutDuplicates;
		},
		apps() {
			return this.$store.getters.apps;
		},
		mountMenuFromSpaceApps() {
			const appsArray = [];
			const spaceApps = this.$store.getters.apps.space.apps;
			// console.log('#### Apps installed and published:', spaceApps);
			if (spaceApps && typeof spaceApps === "object") {
				const apps = Object.values(spaceApps);
				apps.forEach((app) => {
					// console.log('app key:', app.key);
					const appCanBeInPreview = this.menuObject.hasOwnProperty(app.key);
					//we keep it only if it has set up to be in preview
					// console.log('app.inPreview:', app.inPreview);
					// console.log('appCanBeInPreview:', appCanBeInPreview);
					if (app.isInstalled && app.isActive && app.inPreview && appCanBeInPreview) {
						const appObj = this.menuObject[app.key];
						// console.log('app', app);
						// add customized icon to tab 
						appObj.icon = app.iconKey;
						// add customized name to tab
						let titleEdited = false;
						if(app.customizationName){
							appObj.title = this.translate(app.customizationName);
							titleEdited = true;
						}
						if(!titleEdited && app.customization?.customizationName){
							appObj.title = this.translate(app.customization?.customizationName);
							titleEdited = true;
						}
						titleEdited = false;
						this.menu.push(appObj);
						appsArray.push(app.key);
					}
				});
			}
			//console.log('Apps in menu:', appsArray);
			return appsArray;
		},
		id() {
			return this.item?.key;
		},
		isStaff() {
			return this.item?.isStaff;
		},
		isStaffOfThis() {
			return this.item?.isStaffOfThis;
		},
	},
	watch: {
		menu(value) {
			return value;
		},
		item(value) {
			return value;
		},
		isMobile(value) {
			return value;
		},
	},
	async created() {
		//this.isLoading = true;

		await this.fetchApps();
		this.menu.push(this.menuObject.about, this.menuObject.contact);
		const appsArray = this.mountMenuFromSpaceApps;
		// console.log('appsArray', appsArray);
		
		// apps content
		// for(let appKey of this.mountMenuFromSpaceApps){
		// 	//console.log('Getting content from app:', appKey);
		// 	setTimeout(() => {
		// 		this.getAppsContent(appKey);
		// 	}, 200);
		// }

		this.getTickets();
		this.getPlans();

		this.isLoading = false;
	},
	methods: {
		async fetchApps() {
			await this.$store.dispatch("getItems", {
				itemType: "apps",
				storedKey: "space",
				page: 1,
				communitySlug: this.item.slug,
			});
		},
		clickOnTab(tabKey) {
			this.menuPage = tabKey
			// call the items from the tab
			setTimeout(() => {
				this.getAppsContent(tabKey);
			}, 200);
			return 0;
		},
		translate(field) {
			return translateTranslationTable(this.$store.state.locale.currentLocale, field);
		},
		async handleEditItem(poweredByKey, poweredByType) {
			try {
				const response = await this.$store.dispatch("editItem", {
					item: {
						itemType: "communities",
						customName: "communitiesOthers",
						storedKey: this.itemTypeSingle,
						requestConfig: {
							communitySlug: this.item.slug,
							poweredByKey,
							poweredByType,
						},
					},
				});
				this.item.poweredBy = response.data.poweredBy;
				this.item.poweredByType = response.data.poweredByType;
				this.$emit("fetchData");
				this.notifySuccess(this.$t("success-message.general-success-edit"));
			} catch {
				this.notifyError(this.$t("error-message.general-error"));
			}
		},
		getPeopleName(type) {
			if (type.indexOf("people") < 0) {
				type = `people-${type}`;
			}
			let name = this.item.layout.find((item) => item.appKey.toLowerCase() === type.toLowerCase())?.customizationName;
			if (name) {
				name = this.translate(name);
			}
			return name;
		},
		getEntitiesName(type) {
			if (type.indexOf("entity") < 0) {
				type = `entity-${type}`;
			}
			let name = this.item.layout.find((item) => item.appKey.toLowerCase() === type.toLowerCase())?.customizationName;
			if (name) {
				name = this.translate(name);
			}
			return name;
		},
		handleTotalPeopleByTypeUpdated(type, total) {
			this.$set(this.typeHasPeople, type.key, total > 0);
		},
		handleTotalEntityByTypeUpdated(type, total) {
			this.$set(this.typeHasEntity, type.key, total > 0);
		},
		// GET APPS CONTENT
		async getAppsContent(appKey) {
			if (appKey === "people") {
				// PEOPLE
				if (!this.$store.hasModule(PEOPLE_STORE_MODULE_NAME)) {
					this.$store.registerModule(PEOPLE_STORE_MODULE_NAME, peopleStoreModule);
				}

				this.peopleTypes = (
					await this.$store.$service[Service.BackendClient].get("types", {
						params: {
							communityKey: this.id,
							page: 1,
							count: 20,
							modelType: "people",
							selectAll: 1,
							getCommunityTypes: 1,
						},
					})
				)?.data?.data;

				const extra = this.item.layout.find((item) => item.appKey.toLowerCase() === "people-nokey");

				if (extra?.customizationName) {
					this.peopleTypes.push({
						key: "noKey",
						customizationName: extra.customizationName,
					});
				}

				let temp = [];
				if (Array.isArray(this.peopleTypes)) {
					for (const row of this.peopleTypes) {
						const response = await this.$store.dispatch("getItems", {
							itemType: "people",
							page: 1,
							storedKey: `people-${row.key}`,
							forceAPICall: true,
							communityKey: this.id,
							keyNeeded: true,
							requestConfig: {
								count: 1000,
								orderByDate: -1,
								orderBySurname: 1,
								typeKey: row.key !== "noKey" ? row.key : undefined,
							},
						});

						if (response.data.length > 0) {
							temp.push(row);
							this.menu.splice(this.menu.length - 1, 0, this.menuObject.people);
							this.menu.sort((a, b) => a.order - b.order);
						}
					}
				}

				if (extra?.customizationName != null && temp?.length === 2) {
					temp = [
						{
							key: "noKey",
							customizationName: extra.customizationName,
						},
					];
				}

				this.peopleTypes = temp;
			} else if (appKey === "entities") {
				// console.log('Getting entities data');
				// ENTITIES
				if (!this.$store.hasModule(ENTITIES_STORE_MODULE_NAME)) {
					this.$store.registerModule(ENTITIES_STORE_MODULE_NAME, entitiesStoreModule);
				}

				// console.log('Getting types');
				this.entitiesTypes = (
					await this.$store.$service[Service.BackendClient].get("types", {
						params: {
							// communityKey: this.id,
							communitySlug: this.item.slug,
							page: 1,
							count: 20,
							modelType: "entity",
							selectAll: 1,
							getCommunityTypes: 1,
						},
					})
				)?.data?.data;

				// console.log('this.entitiesTypes', this.entitiesTypes);

				const extra = this.item.layout.find((item) => item.appKey.toLowerCase() === "entities-nokey");
				// console.log('extra', extra);

				if (extra?.customizationName) {
					this.entitiesTypes.push({
						key: "noKey",
						customizationName: extra.customizationName,
					});
				}

				// const types =
				// 	(await this.$store.dispatch(ENTITIES_ACTIONS.fetchTypes, {
				// 		key: this.id,
				// 		force: true,
				// 	})) ?? [];
				// let index = 0;
				let temp = [];
				for (const row of this.entitiesTypes) {
					const response = await this.$store.dispatch("getItems", {
						itemType: "entities",
						page: 1,
						storedKey: `entities-${row.key}`,
						forceAPICall: true,
						communityKey: this.id,
						// communitySlug: this.item?.slug,
						requestConfig: {
							count: 1000,
							orderByDate: -1,
							orderBySurname: 1,
							typeKey: row.key,
						},
					});
					// console.log('entitites getItems response:', response);
					if (response.data.length > 0) {
						this.temp.push(row);
						this.menu.splice(this.menu.length - 1, 0, this.menuObject.entities);
						this.menu.sort((a, b) => a.order - b.order);
					}

					// index++;
				}

				if (extra?.customizationName != null && temp?.length === 2) {
					temp = [
						{
							key: "noKey",
							customizationName: extra.customizationName,
						},
					];
				}

				this.entitiesTypes = temp;
			} else if (appKey === "agenda") {
				// AGENDA
				const response = await this.$store.dispatch("getItems", {
					itemType: "events",
					page: 1,
					forceAPICall: true,
					perPage: 100,
					customName: "agenda",
					communitySlug: this.item?.slug,
					requestConfig: {
						visibleOncommunityKey: this.item?.key,
						isForAgenda: true,
					},
				});
				this.agenda = response.data;
				if (response.data.length > 0) {
					this.menu.splice(this.menu.length - 1, 0, this.menuObject.agenda);
					this.menu.sort((a, b) => a.order - b.order);
				}
			} else if (appKey === "events") {
				// EVENTS
				const response = await this.$store.dispatch("getItems", {
					itemType: "communities/simply",
					customName: "communitiesOthers",
					storedKey: "events",
					page: 1,
					forceAPICall: true,
					communitySlug: this.item?.slug,
					perPage: 16,
					requestConfig: {
						communityParentSlug: this.item?.slug,
						orderByDate: -1,
					},
				});
				//console.log('getItems events response:', response);
				this.events = response.data;
				if (response?.data) {
					this.menu.splice(this.menu.length - 1, 0, this.menuObject.events);
					this.menu.sort((a, b) => a.order - b.order);
				}
			} else if (appKey === "documents") {
				// documents
				this.documents = await this.fetchMediaData("document")
				if (this.documents?.data) {
					this.menu.splice(this.menu.length - 1, 0, this.menuObject.documents);
					this.menu.sort((a, b) => a.order - b.order);
				}
			} else if (appKey === "presentations") {
				// presentations
				this.presentations = await this.fetchMediaData("presentation")
				if (this.presentations?.data) {
					this.menu.splice(this.menu.length - 1, 0, this.menuObject.presentations);
					this.menu.sort((a, b) => a.order - b.order);
				}
			} else if (appKey === "spreadsheets") {
				// spreadsheets
				this.spreadsheets = await this.fetchMediaData("spreadsheet")
				if (this.spreadsheets?.data) {
					this.menu.splice(this.menu.length - 1, 0, this.menuObject.spreadsheets);
					this.menu.sort((a, b) => a.order - b.order);
				}
			} else if (appKey === "videos") {
				// videos
				this.videos = await this.fetchMediaData("video")
				if (this.videos?.data) {
					this.menu.splice(this.menu.length - 1, 0, this.menuObject.videos);
					this.menu.sort((a, b) => a.order - b.order);
				}
			} else if (appKey === "photos") {
				// photos
				this.photos = await this.fetchMediaData("photo")
				if (this.photos?.data) {
					this.menu.splice(this.menu.length - 1, 0, this.menuObject.photos);
					this.menu.sort((a, b) => a.order - b.order);
				}
			} else if (appKey === "audios") {
				// audios
				this.audios = await this.fetchMediaData("audio")
				if (this.audios?.data) {
					this.menu.splice(this.menu.length - 1, 0, this.menuObject.audios);
					this.menu.sort((a, b) => a.order - b.order);
				}
			} else if (appKey === "services") {
				// SERVICES
				const response = await this.$store.dispatch("getItems", {
					itemType: "communities/simply",
					customName: "communitiesOthers",
					storedKey: "services-detail",
					page: 1,
					forceAPICall: true,
					communitySlug: this.item?.slug,
					perPage: 16,
					requestConfig: {
						communityParentSlug: this.item?.slug,
						orderByDate: -1,
						isService: true,
					},
				});
				//console.log('response', response);
				this.services = response.data;
				if (response?.data) {
					this.menu.splice(this.menu.length - 1, 0, this.menuObject.services);
					this.menu.sort((a, b) => a.order - b.order);
				}
			}else if (appKey === "subcommunities") {
				// SUBCOMMUNITIES
				const response = await this.$store.dispatch("getItems", {
					itemType: "communities/simply",
					customName: "communitiesOthers",
					storedKey: "subcommunities",
					page: 1,
					forceAPICall: true,
					communitySlug: this.item?.slug,
					perPage: 16,
					requestConfig: {
						communityParentSlug: this.item?.slug,
						orderByDate: -1,
					},
				});
				this.subcommunities = response.data;
				if (response?.data) {
					this.menu.splice(this.menu.length - 1, 0, this.menuObject.subcommunities);
					this.menu.sort((a, b) => a.order - b.order);
				}
			}else if (appKey === "sections") {
				// SECTIONS
				const response = await this.$store.dispatch("getItems", {
					itemType: "communities/simply",
					customName: "communitiesOthers",
					storedKey: "sections",
					page: 1,
					forceAPICall: true,
					communitySlug: this.item?.slug,
					perPage: 16,
					requestConfig: {
						communityParentSlug: this.item?.slug,
						orderByDate: -1,
					},
				});
				this.sections = response.data;
				if (response?.data) {
					this.menu.splice(this.menu.length - 1, 0, this.menuObject.sections);
					this.menu.sort((a, b) => a.order - b.order);
				}
			}else if (appKey === "organizations") {
				// ORGANIZATIONS
				const response = await this.$store.dispatch("getItems", {
					itemType: "communities/simply",
					customName: "communitiesOthers",
					storedKey: "organizations",
					page: 1,
					forceAPICall: true,
					communitySlug: this.item?.slug,
					perPage: 16,
					requestConfig: {
						communityParentSlug: this.item?.slug,
						orderByDate: -1,
					},
				});
				this.organizations = response.data;
				if (response?.data) {
					this.menu.splice(this.menu.length - 1, 0, this.menuObject.organizations);
					this.menu.sort((a, b) => a.order - b.order);
				}
				// console.log('this.organizations', this.organizations);
			}else if (appKey === "challenges") {
				// CHALLENGES
				const response = await this.$store.dispatch("getItems", {
					itemType: "communities/simply",
					customName: "communitiesOthers",
					storedKey: "challenges",
					page: 1,
					forceAPICall: true,
					communitySlug: this.item?.slug,
					perPage: 16,
					requestConfig: {
						communityParentSlug: this.item?.slug,
						orderByDate: -1,
					},
				});
				this.challenges = response.data;
				if (response?.data) {
					this.menu.splice(this.menu.length - 1, 0, this.menuObject.challenges);
					this.menu.sort((a, b) => a.order - b.order);
				}
			}else if (appKey === "courses") {
				// COURSES
				const response = await this.$store.dispatch("getItems", {
					itemType: "communities/simply",
					customName: "communitiesOthers",
					storedKey: "courses",
					communitySlug: this.item?.slug,
					page: 1,
					forceAPICall: true,
					perPage: 16,
					requestConfig: {
						// communityParentSlug: this.item?.slug,
						orderByDate: -1,
					},
				});
				this.courses = response.data;
				if (response?.data) {
					this.menu.splice(this.menu.length - 1, 0, this.menuObject.courses);
					this.menu.sort((a, b) => a.order - b.order);
				}
			}else if (appKey === "jobs") {
				// jobs
				const response = await this.$store.dispatch("getItems", {
					itemType: "jobs",
					storedKey: "jobs",
					page: 1,
					forceAPICall: true,
					communitySlug: this.item?.slug,
					perPage: 16,
					requestConfig: {
						// communityParentSlug: this.item?.slug,
						orderByDate: -1,
					},
				});
				this.jobs = response.data;
				if (response?.data) {
					this.menu.splice(this.menu.length - 1, 0, this.menuObject.jobs);
					this.menu.sort((a, b) => a.order - b.order);
				}
			}else if (appKey === "projects") {
				// PROJECTS
				const response = await this.$store.dispatch("getItems", {
					itemType: "communities/simply",
					customName: "communitiesOthers",
					storedKey: "projects",
					page: 1,
					forceAPICall: true,
					perPage: 16,
					requestConfig: {
						isProject: true,
						communityParentSlug: this.item?.slug,
						orderByDate: -1,
					},
				});
				this.projects = response.data;
				if (response?.data) {
					this.menu.splice(this.menu.length - 1, 0, this.menuObject.projects);
					this.menu.sort((a, b) => a.order - b.order);
				}
			}else if (appKey === "products") {
				// PRODUCTS
				const response = await this.$store.dispatch("getItems", {
					itemType: "products",
					page: 1,
					forceAPICall: true,
					communitySlug: this.item?.slug,
					requestConfig: {
						count: 16,
						orderByDate: -1,
					},
				});

				this.products = response.data;
				if (response?.data) {
					this.menu.splice(this.menu.length - 1, 0, this.menuObject.products);
					this.menu.sort((a, b) => a.order - b.order);
				}
			}else if (appKey === "ideas") {
				// IDEAS
				await this.$store.dispatch("getItems", {
					itemType: "ideas",
					page: 1,
					perPage: 100,
					forceAPICall: true,
					// communityKey: this.id,
					communitySlug: this.item?.slug,
				});
				if (this.apps?.apps?.[23]) {
					this.menu.splice(this.menu.length - 1, 0, this.menuObject.ideas);
					this.menu.sort((a, b) => a.order - b.order);
				}
			}else if (appKey === "members") {
				// MEMBERS
				const response = await this.$store.dispatch("getItems", {
					itemType: "communityMembers",
					page: 1,
					forceAPICall: true,
					perPage: 100,
					storedKey: 'members',
					communitySlug: this.item?.slug,
					requestConfig: {
						visibleOncommunityKey: this.item?.key,
						getMinimalData: 1,
					},
				});
				this.members = response.data;
				if (response?.data) {
					this.menu.splice(this.menu.length - 1, 0, this.menuObject.members);
					this.menu.sort((a, b) => a.order - b.order);
				}
			}else if (appKey === "contacts") {
				// CONTACTS
				const response = await this.$store.dispatch("getItems", {
					itemType: "contacts",
					page: 1,
					forceAPICall: true,
					perPage: 100,
					storedKey: 'contacts',	//this.item.key,
					communitySlug: this.item?.slug,
					requestConfig: {
						visibleOncommunityKey: this.item?.key,
					},
				});
				this.contacts = response.data;
				if (response?.data) {	//.length > 0
					this.menu.splice(this.menu.length - 1, 0, this.menuObject.contacts);
					this.menu.sort((a, b) => a.order - b.order);
				}
			}else if (appKey === "societies") {
				// SOCIETIES
				const response = await this.$store.dispatch("getItems", {
					itemType: "organizations",
          			customName: 'shareOrganizations',
					page: 1,
					forceAPICall: true,
					perPage: 100,
					storedKey: 'societies',
					communitySlug: this.item?.slug,
					requestConfig: {
						visibleOncommunityKey: this.item?.key,
						listingType: 0,
					},
				});
				this.societies = response.data;
				// console.log('this.societies', this.societies);
				if (response.data && response.data.length > 0) {
					this.menu.splice(this.menu.length - 1, 0, this.menuObject.societies);
					this.menu.sort((a, b) => a.order - b.order);
				}
			}
		},
		//PLANS
		getPlans() {
			if (this.item?.hasPrice) {
				this.menu.splice(this.menu.length - 1, 0, this.menuObject.plans);
				this.menu.sort((a, b) => a.order - b.order);
			}
		},
		//TICKETS
		getTickets() {
			if (this.item?.room) {
				this.menu.splice(this.menu.length - 1, 0, this.menuObject.tickets);
				this.menu.sort((a, b) => a.order - b.order);
			}
		},
		async fetchMediaData(type, force = false, lastPage = 1) {
			// console.log('Fetching data media items in MediaList.vue');
			const getItemsData = {
				itemType: "media",
				storedKey: type,
				page: lastPage,
				forceAPICall: true,
				perPage: 20,
				communitySlug: this.item.slug,
				requestConfig: {
					morphType: type,
					orderByDate: -1,
				},
			};
			// console.log('getItemsData', getItemsData);
			const response = await this.$store.dispatch("getItems", getItemsData);
			// console.log('getItems media response', response)
			const data = response?.data;
			return data;
		},
		placeholderMedia(type) {
		return PlaceholdersImage(type);
		},
		onTypeEmpty(typeKey) {
			// console.log('calling onTypeEmpty() with typeKey:', typeKey);
			const elem = document.getElementById("entities-type-"+typeKey);
			// console.log('elem', elem);
			if(elem){
				// console.log('hide this container type');
				// elem.style.visibility = 'hidden';
				elem.style.display = 'none';
			}
		}
	},
};
</script>

<style lang="scss" scoped>
@import "~@core/scss/base/bootstrap-include";
.menu-text {
	color: lighten($dark, 40%);
}
</style>
