<template>
  <component
    :is="containerComponent"
    id="events"
    v-slot="{ item }"
    :item-name="$t('events.title')"
    :request-config="requestConfig"
    :filter="filter"
    @items-loaded="$emit('items-loaded', $event)"
  >
    <event-card :event="item" />
  </component>
</template>

<script>
import ContainerMixin from '@core/components/containers/ContainerMixin';
import OrderBy from '@core/constants/OrderBy';
import EventCard from './EventCard.vue';

// EventsList.vue
export default {
  components: {
    EventCard,
  },
  mixins: [ContainerMixin],
  props: {
    filter: {
      type: Function,
      default: null,
    },
  },
  computed: {
    requestConfig() {
      return { orderByDate: OrderBy.DESC };
    },
  },
};
</script>
