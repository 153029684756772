import { render, staticRenderFns } from "./CollectivesList.vue?vue&type=template&id=227dad8e&scoped=true&"
import script from "./CollectivesList.vue?vue&type=script&lang=js&"
export * from "./CollectivesList.vue?vue&type=script&lang=js&"
import style0 from "./CollectivesList.vue?vue&type=style&index=0&id=227dad8e&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "227dad8e",
  null
  
)

export default component.exports