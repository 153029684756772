<template>
  <spaces-list 
    item-type="challenges" 
    item-type-single="challenge" 
  />
</template>

<script>

export default {
  name: 'ChallengesList',
  components: { 
    SpacesList: () => import('@/views/collectives-list/SpacesList.vue' /* webpackChunkName: "SpacesList" */)
  },

};
</script>
