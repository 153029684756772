<template>
  <div>
    <b-card
      class="event card small-card small-card-hover p-0 overflow-hidden "
      :class="item.isPublished ? '' : 'no-published'"
      :style="
        item.logoURL
          ? { backgroundImage: 'url(' + item.logoURL + ')', backgroundSize: 'cover' }
          : item.bgURL?{ backgroundImage: 'url(' + item.bgURL + ')', backgroundSize: 'cover' } :{ backgroundImage: 'url(' + imagePlaceholder + ')', backgroundSize: 'cover' }
      "
    >
      <!--       <b-link :href="getCollectiveUrl(item)" class="h-100">
 -->
      <b-link
        :to="{ name: 'course', params: { slug: item.slug, communityId: $route.params.communityId } }"
        class="h-100"
      >
        <div class="event--container w-100">
          <div class="event--text pt-4 w-100 px-1">
            <p class="w-100 text-light text-ellipsis-lines-1 mt-1 h4 mb-25">
              {{ translate(item.name) }}
            </p>
            <p class="w-100 font-weight-200 headline text-light min-height-2 text-ellipsis-lines-1 mb-25">
              {{ translate(item.headline) }}
            </p>
            <b-card-group v-if="item.startDate" class="mb-25">
              <b-card-text
                class="text-white font-weight-bold mb-0"
              >
                {{ datesLabel }}
              </b-card-text>
            </b-card-group>
            <!-- <b-card-group v-else class="mb-25">
              <b-card-text class="text-secondary mb-0">
                {{ $t('events.event-details.no-dates-yet') }}
              </b-card-text>
            </b-card-group> -->

            <div class="d-flex mb-25">
              <b-card-group class="mb-0 mr-3">
                <b-card-text v-if="item.modality === 'online'" class="text-light small">
                  <b-icon-camera-video font-scale="1.2" class="mr-50" />
                  {{ item.modality != null ? $t(`events.event-details.${item.modality}`) : null }}
                </b-card-text>
                <b-card-text v-else class="text-light small">
                  <b-icon-geo-alt font-scale="1.2" class="mr-25" />
                  {{ item.modality != null ?$t(`events.event-details.${item.modality}`) : null }}
                </b-card-text>
              </b-card-group>
              <b-card-group v-if="item.showPrice" class="m-0 text-secondary text-light small d-flex align-items-center">
                <feather-icon icon="DollarSignIcon" size="15" class="mr-50" />
                <b-card-text v-if="item.hasPrice">
                  {{ $t('events.list.no-free') }}
                </b-card-text>
                <b-card-text v-else>
                  {{ $t('events.list.free') }}
                </b-card-text>
              </b-card-group>
            </div>
            <b-card-group v-if="item.parentCollective" class="d-flex align-items-center m-0">
              <b-avatar
                size="16"
                :src="getImageSrc(item.parentCollective.logoURL)"
                :alt="translate(item.parentCollective.name)"
                class="user-avatar mr-50"
              />

              <b-card-text class="text-muted small">
                {{ translate(item.parentCollective.name) }}
              </b-card-text>
            </b-card-group>
          </div>
        </div>
      </b-link>
    </b-card>
  </div>
</template>

<script>
import { getImageResource } from '@/@core/utils/image-utils';
import Placeholder from '@/assets/images/placeholders/light/placeholder-dark.jpeg';
import { checkPermissions } from '@/@core/utils/roles-utils';
import { getCollectiveUrl } from '@/@core/utils/collective';
import { DateTime } from 'luxon';
import moment from "moment";
import { translateTranslationTable } from '@/@core/libs/i18n/utils';

export default {
  name: 'CourseItem',
  props: [
    'item'
  ],
  data() {
    return {
      isShareModalVisible: false,
    };
  },
  computed: {
    canShare() {
      return checkPermissions('share', 'events', this.loggedMemberRoles, this.$store.getters.currentCollective);
    },
    imagePlaceholder() {
      return Placeholder;
    },
    dateTimeISOStartDate() {
      return DateTime.fromISO(this.item.startDate);
    },
    dateTimeISOEndDate() {
      return DateTime.fromISO(this.item.endDate);
    },
    startsAndEndsInSameYear() {      
      return this.dateTimeISOStartDate.hasSame(this.dateTimeISOEndDate, 'year');
    },
    startsAndEndsInSameMonth() {
      return this.startsAndEndsInSameYear && this.dateTimeISOStartDate.hasSame(this.dateTimeISOEndDate, 'month');
    },
    startsAndEndsInSameDay() {
      return this.startsAndEndsInSameMonth && this.dateTimeISOStartDate.hasSame(this.dateTimeISOEndDate, 'day');
    },
    datesLabel() {
      // console.log('################# NEW #################');
      // console.log('this.item.startDate', this.item.startDate);
      // console.log('this.item.endDate', this.item.endDate);
      // console.log('this.dateTimeISOStartDate', this.dateTimeISOStartDate);
      // console.log('this.dateTimeISOEndDate', this.dateTimeISOEndDate);
      // console.log('this.startsAndEndsInSameYear', this.startsAndEndsInSameYear);
      // console.log('this.startsAndEndsInSameMonth', this.startsAndEndsInSameMonth);
      // console.log('this.startsAndEndsInSameDay', this.startsAndEndsInSameDay);

      let label = '';
      if(this.startsAndEndsInSameDay){
        // console.log('mismo año, mes y dia');
        // 
        label = moment(this.item.startDate).format('ddd DD MMM YYYY, HH:mm') + ' - ' + moment(this.item.endDate).format('HH:mm');
      } else if(this.startsAndEndsInSameMonth) {
        // console.log('mismo año y mes, pero diferente dia');
        // mismo año y mes, pero diferente dia
        label = moment(this.item.startDate).format('ddd DD') + ' - ' + moment(this.item.endDate).format('ddd DD MMM YYYY') + ' - ' +
        moment(this.item.startDate).format('HH:mm');
      } else if(this.startsAndEndsInSameYear) {
        // console.log('mismo año, pero diferente mes');
        // mismo año, pero diferente mes
        label = moment(this.item.startDate).format('ddd DD MMM') + ' - ' + moment(this.item.endDate).format('ddd DD MMM YYYY') + ' - ' +
        moment(this.item.startDate).format('HH:mm');
      } else {
        // diferente año
        // console.log('diferente año');
        label = moment(this.item.startDate).format('ddd DD MMM YYYY, HH:mm') + ' - ' + moment(this.item.endDate).format('ddd DD MMM YYYY, HH:mm');
      }

      // console.log('label', label);
      label = label + ' h (' + this.timezone(this.item.startDate) + ')';
      return label;
    }
  },

  methods: {
    getCollectiveUrl(item) {
      return getCollectiveUrl(item);
    },
    getImageSrc(url) {
      return getImageResource(url);
    },
    currentLocale() {
      return this.$store.state.locale.currentLocale;
    },
    isStaff() {
      return this.$store.getters.currentCollective.isStaff;
    },
    translate(field) {
      return translateTranslationTable(
        this.$store.state.locale.currentLocale,
        field,
      );
    },
    dayDate(date) {
      const dateOptions = {
        weekday: 'short',
        month: 'long',
        day: 'numeric',
      };
      const eventDate = new Date(date);
      return eventDate.toLocaleDateString('es-ES', dateOptions);
    },
    like(item) {
      if (item.likedByMember) {
        item.likedByMember = false;
        item.totalLikes--;
      } else {
        item.likedByMember = true;
        item.totalLikes++;
      }
      this.toggleLike(item);
    },
    timezone(timestamp) {
      return DateTime.fromISO(timestamp).offsetNameShort;
    },
  },
};
</script>

<style lang="scss" scoped>
.event {
  height: 22em;
  width: 22em;
  background-repeat: no-repeat;
  background-position: center;
  border-radius: 0.8em;
  border: 0;
  position: relative;

  &--container {
    height: 100%;
    background: linear-gradient(transparent, rgba(0, 0, 0, 0.9));
  }
  &--text {
    height: 75%;
    position: absolute;
    bottom: 0;
  }
}
.headline {
  font-weight: 200 !important;
}
.no-published {
  filter: grayscale(100%);
  opacity: 0.6;
  border-color: black;
  border-style: dashed !important;
  border-width: 3px !important;
}
</style>
