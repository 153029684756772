<template>
  <div class="collectives-container">
    <collective-card
      v-for="community in communities"
      :key="community.key"
      class="x-scroll-element mx-1 mb-2"
      :collective="community"
      :isPreview="isPreview"
    />
  </div>
</template>

<script>
import CollectiveFilter from '@/views/apps/collectives/constants/CollectiveFilter';
import CollectiveCard from './CollectiveCard.vue';

export default {
  name: 'CollectivesList',
  components: {
    CollectiveCard,
  },
  props: {
    collectiveFilter: {
      type: String,
      required: false,
      validator(collectiveFilter) {
        return Object.values(CollectiveFilter).includes(collectiveFilter);
      },
    },
    communities: {
      type: Array,
      required: true,
    },
    isPreview: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      page: 1,
      isLoading: false,
      isLoadingNextPage: false,
    };
  },
};
</script>

<style scoped>
.collectives-container {
  display: inline-flex;
  overflow-x: scroll;
  width: 100%;
}
</style>