<template>
  <b-row>
    <!-- ** IDEAS ** -->
    <b-col cols="12" class="pr-5">
      <!-- Header -->
      <div class="d-flex justify-content-between">
        <h5 class="pt-1 px-2 pb-0 mb-2">
          <span v-if="ideas.length === 1">
            {{ $t("challenges.detail-idea") }}
          </span>
          <span v-else>
            {{ $t("challenges.detail-ideas", {item:ideas.length}) }}
          </span>
        </h5>
        <b-button
          v-if="!item.userNotInsideCommunity"
          class="mb-2"
          variant="outline-primary"
          @click="openCreateModal"
        >
          {{ $t("challenges.detail-create-button") }}
        </b-button>
      </div>
      <!-- Ideas loop -->
      <div>
        <b-row
          v-for="(idea, index) in ideas"
          :key="index"
          :class="index > 0?'border-top py-2':''"
        >
          <b-col v-if="idea.ownedByMember" cols="12" class="pt-1 px-2 d-flex align-items-center justify-content-between">
            <b-link
              :to="{
                name: 'member-details',
                params: { username: idea.ownedByMember.username },
              }"
            >
              <user-avatar
                size="38"
                :user="idea.ownedByMember"
                variant="light-primary"
                class="user-avatar ml-1"
              />
              <span v-if="idea.ownedByMember" class="mb-1 ml-50 h5">
                {{ idea.ownedByMember.name }} {{ idea.ownedByMember.surname }}
              </span>
            </b-link>
            <small class="text-muted d-none d-md-block">
              {{ timestampToTime(idea.createdAt) }} - {{ timestampToHour(idea.createdAt) }}
            </small>
          </b-col>
          <b-col
            class="pt-2 pl-3 pr-2"
          >
            <div>
              <div class="d-flex justify-content-between mb-1">
                <h4>
                  {{ idea.name }}
                </h4>
                <div class="d-flex align-items-center text-primary" style="width:46px">
                  <b-link v-if="canRemove">
                    <feather-icon
                      v-b-tooltip.hover.bottom
                      icon="Trash2Icon"
                      size="16"
                      class="mr-1 create"
                      :title="$t('unshare.simple-title')"
                      @click="openUnshareModal(idea)"
                    />
                  </b-link>
                  <b-link v-if="canEdit" href="#">
                    <feather-icon
                      v-b-tooltip.hover.bottom
                      icon="Edit2Icon"
                      size="16"
                      class="create"
                      :title="$t('form-create-item.edit')"
                      @click="openCreateModal(idea)"
                    />
                  </b-link>
                </div>
              </div>

              <p v-html="idea.description" />
              <small class="text-muted d-block d-md-none mb-2">{{ timestampToTime(idea.createdAt) }} - {{ timestampToHour(idea.createdAt) }}</small>
            </div>
          </b-col>
        </b-row>
      </div>
    </b-col>
    <!-- ** CREATE IDEA ** -->
    <b-modal
      id="modal-create-idea"
      :title="ideaInput.key?$t('challenges.modal-edit-title'):$t('challenges.detail-create-challenge-title')"
      centered
      hide-footer
      size="lg"
    >
      <b-form
        class="form-challenge-idea my-2"
        @submit.prevent="handleCreateIdea"
      >
        <b-form-group :label="$t('form-create-item.title')" label-for="title">
          <b-form-input
            id="title"
            v-model="ideaInput.name"
          />
        </b-form-group>
        <label
          for="description"
        >{{ $t("form-create-item.description") }}</label>
        <quill-editor
          v-model="ideaInput.description"
          class="mb-2"
        />
        <div class="float-right mb-2">
          <b-button variant="primary" type="submit">
            {{ ideaInput.key?$t("challenges.detail-edit-button") : $t("challenges.detail-create-button") }}
          </b-button>
        </div>
      </b-form>
    </b-modal>
    <unshare-modal
      v-model="isUnshareModalVisible"
      model-type="idea"
      @remove-item="handleRemoveItem"
    />
  </b-row>
</template>

<script>
import moment from 'moment';
import { checkPermissions } from '@/@core/utils/roles-utils';
import UnshareModal from '@/@core/components/modal/UnshareModal.vue';
import ToastificationContentVue from '@/@core/components/toastification/ToastificationContent.vue';
import ToastNotificationsMixin from '@core/mixins/toast-notifications/ToastNotificationsMixin';
import { quillEditor } from 'vue-quill-editor';
import UserAvatar from '@core/components/user/UserAvatar.vue';

export default {
  name: 'IdeasDetail',
  components: { UnshareModal, quillEditor, UserAvatar },
  mixins: [ToastNotificationsMixin],
  props: {
    item: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      ideaInput: {},
      itemToDelete: '',
      isUnshareModalVisible: false,
      lastLoadedPage: 1,
    };
  },
  computed: {
    slug() {
      return this.$route.params.slug;
    },
    ideasData() {
      return this.$store.getters.currentChallengeIdeas;
    },
    ideas() {
      return this.ideasData.unpaginated;
    },
    loggedMemberRoles() {
      return this.$store.getters.loggedMemberRoles;
    },
    currentCollective() {
      return this.$store.getters.currentCollective;
    },
    canRemove() {
      return (
        checkPermissions(
          'update',
          'challenge',
          this.loggedMemberRoles,
          this.currentCollective,
        )
      );
    },
    canEdit() {
      return (
        checkPermissions(
          'update',
          'challenge',
          this.loggedMemberRoles,
          this.currentCollective,
        )
      );
    },
    canAdd() {
      return !this.item.userNotInsideCommunity
|| checkPermissions(
  'create',
  'challenge',
  this.loggedMemberRoles,
  this.currentCollective,
);
    },
  },
  async created() {
    await this.fetchIdea();
  },
  methods: {
    async fetchIdea() {
      await this.$store.dispatch('getItems', {
        itemType: 'ideas',
        customName: 'currentChallengeIdeas',
        page: this.lastLoadedPage,
        perPage: 50,
        forceAPICall: true,
        requestConfig: {
          challengeKey: this.item.key,
          orderByDate: -1,
        },
      });
    },
    timestampToTime(timestamp) {
      moment.locale(this.currentLocale);
      return moment(timestamp).format('LL');
    },
    timestampToHour(timestamp) {
      return moment(timestamp).format('HH:mm');
    },
    openCreateModal(item = { name: '', description: '' }) {
      this.ideaInput = {};
      this.ideaInput = { ...item };
      this.$bvModal.show('modal-create-idea');
    },
    openUnshareModal(item) {
      this.itemToDelete = item.key;
      this.isUnshareModalVisible = true;
    },
    async handleCreateIdea() {
      if (!this.ideaInput.name) {
        return;
      }
      try {
        if (this.ideaInput.key) {
          await this.$store.dispatch('editItem', {
            communitySlug: this.slug,
            item: {
              itemType: 'ideas',
              customName: 'currentChallengeIdeas',
              requestConfig: {
                name: this.ideaInput.name,
                description: this.ideaInput.description,
                challengeKey: this.item.key,
                key: this.ideaInput.key,
              },
            },
          });
        } else {
          await this.$store.dispatch('createItem', {
            communitySlug: this.slug,
            item: {
              itemType: 'ideas',
              customName: 'currentChallengeIdeas',
              requestConfig: {
                name: this.ideaInput.name,
                description: this.ideaInput.description,
                challengeKey: this.item.key,
                orderByDate: 1,
              },
            },
          });
        }
        this.ideaInput = {};
        this.$bvModal.hide('modal-create-idea');
        this.notifySuccess(this.$t('success-message.general-success-create'));
      } catch {
        this.notifyError(this.$t('error-message.general-error'));
      }
    },
    async handleRemoveItem(response) {
      if (response === true) {
        try {
          await this.$store.dispatch('unshareModel', {
            communitySlug: this.slug,
            customName: 'currentChallengeIdeas',
            item: {
              modelName: 'idea',
              tableName: 'ideas',
              modelKey: this.itemToDelete,
            },
          });
          this.$toast({
            component: ToastificationContentVue,
            props: {
              title: this.$t('unshare.toast-messages.success', {
                item: this.$t('unshare.singular-items.challenge'),
              }),
              icon: 'CheckIcon',
              variant: 'success',
            },
          });
        } catch {
          this.$toast({
            component: ToastificationContentVue,
            props: {
              title: this.$t('unshare.toast-messages.error', {
                item: this.$t('unshare.singular-items.challenge'),
              }),
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          });
        }
      }
    },

  },
};
</script>

<style>

</style>
