<template>
  <b-card no-body class="float-left mx-1 mb-2 p-0 card-standard">
    <b-link
      :to="canView ? { name: 'product', params: { communityId: community.slug, id: item.key } } : null"
    >
      <!-- Image -->
      <b-card-body class="pb-2">
        <div class="item-img text-center">
          <b-img
            :alt="`${item.name}`"
            fluid
            :src="getImageSrc(item.bgURL) || productPlaceholder"
            class="card-image-medium"
          />
        </div>
        <!-- Product Details -->
        <b-card-text v-if="item.unitPrice && item.unitPrice > 0">
          <h5 class="font-weight-bold precio">
            {{ item.unitPrice }} {{ item.currency.ISO }}
          </h5>
        </b-card-text>
        <div v-else class="min-height-1 mt-1" />

        <h4 class="text-ellipsis-lines-2 titulo">
          {{ item.name }}
        </h4>
        <span
          v-if="item.headline"
          class="min-height-4 html-text-ellipsis-2 text-secondary"
          v-html="item.headline"
        />
        <!-- <span
                  v-if="item.description"
                  class="min-height-3 html-text-ellipsis-2 text-secondary"
                  v-html="item.description"
                /> -->
        <div v-else class="min-height-3" />
      </b-card-body>
    </b-link>
  </b-card>
</template>

<script>
import { translateTranslationTable } from "@/@core/libs/i18n/utils";
import { getImageResource } from "@/@core/utils/image-utils";
import { checkPermissions } from "@/@core/utils/roles-utils";
import ProductPlaceholder from "@/assets/images/placeholders/light/placeholder-dark.jpeg";

export default {
  name: "ServiceCard",
  props: {
    item: {
      type: Object,
      required: true,
    },
    community: {
      type: Object,
      default: () => {},
    },
  },
  computed: {
    collective() {
      return this.$store.getters.currentCollective;
    },
    loggedMemberRoles() {
      return this.$store.getters.loggedMemberRoles;
    },
    canView() {
      return checkPermissions(
        "view",
        "product",
        this.loggedMemberRoles,
        this.community || this.collective
      );
    },
    productPlaceholder() {
      return ProductPlaceholder;
    },
  },
  methods: {
    translate(field) {
      return translateTranslationTable(this.currentLocale, field);
    },
    getImageSrc(url) {
      return getImageResource(url);
    },
  },
};
</script>

<style>
.titulo {
  font-size: 1.2em;
}
.precio {
  padding-top: 15px;
  font-size: 1.5em;
  font-weight: 900 !important;
}
.item-img {
  height: 200px !important;
}
.card-standard {
  height: 420px !important;
}
</style>
